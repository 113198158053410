import { Service } from "../constants";
export const add_favorite_masters_saga = (data) => {
    return ({
        type: Service.ADD_FAVORITE_MASTERS_SAGA,
        payload: data
    });
};
export const remove_favorite_masters_saga = (data) => {
    return ({
        type: Service.REMOVE_FAVORITE_MASTERS_SAGA,
        payload: data
    });
};
export const get_services_by_address_saga = (data) => {
    return ({
        type: Service.GET_SERVICES_BY_ADDRESS,
        payload: data
    });
};
export const add_favorite_masters = (data) => {
    return ({
        type: Service.ADD_FAVORITE_MASTERS,
        payload: data
    });
};
export const service_act = (data) => {
    return ({
        type: Service.GET_SERVICE,
        payload: data
    });
};
export const city_act = (data) => {
    return ({
        type: Service.GET_CITY,
        payload: data
    });
};
export const city_act_saga = (data) => {
    return ({
        type: Service.GET_CITY_SAGA,
        payload: data
    });
};
export const plus_service = () => {
    return ({
        type: Service.PLUS_SERVICE,
    });
};
export const service_saga = (data) => {
    return ({
        type: Service.GET_SERVICE_SAGA,
        payload: data
    });
};
export const master_saga = (data) => {
    return ({
        type: Service.GET_MASTER_SAGA,
        payload: data
    });
};
export const get_master = (data) => {
    return ({
        type: Service.GET_MASTER,
        payload: data
    });
};
export const get_master_loading = () => {
    return ({
        type: Service.GET_MASTER_LOADING,
    });
};
export const selected_master_saga = (data) => {
    return ({
        type: Service.GET_SELECTED_MASTER_SAGA,
        payload: data
    });
};
export const selected_master_loading = (data) => {
    return ({
        type: Service.SELECTED_MASTER_LOADING,
        payload: data
    });
};
export const selected_master = (data) => {
    return ({
        type: Service.GET_SELECTED_MASTER,
        payload: data
    });
}
// GET_SELECTED_MASTER_SAGA