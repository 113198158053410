import { call, put, debounce, takeEvery } from 'redux-saga/effects';
import { Service } from "../constants";
import { Master } from "../constants";
import {
    master_data,
    master_data_act,
    post_files_act,
    review_list_loading,
    review_act,
    master_filter_act,
    master_schedule,
    master_schedule_loading,
    create_order,
    delete_master_schedule,
    create_order_loading,
    available_days,
    service_loader,
    set_closest_cities,
    set_closest_masters_posts,
} from "../actions/master";
import {
    service_act,
    get_master,
    get_master_loading,
    selected_master,
    selected_master_loading,
    add_favorite_masters,
    city_act
} from "../actions/service";
import Category from '../../services/api/service';
import { setApiAuthorizationHeader } from "../../services/authinstance";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { i18n } from 'next-i18next';
const queryString = require('query-string');
const success_notify = () => toast.success("success", {
    position: toast.POSITION.TOP_CENTER
});
const error_notify = (text) => {
    return toast.error(text ? text : "error", {
        position: toast.POSITION.TOP_CENTER
    });
};
function* service_saga(data) {
    try {
        const { more, ...rest } = data.payload;
        let query = queryString.stringify(rest);
        const service = yield Category.get_service(query);
        yield put(service_act({ ...service, more }));
    } catch (e) {
        console.log(e);
    }
}
function* get_city_saga(data) {
    try {
        if (data.search) {
            yield put(city_act({ city_loading: true }));
            const city = yield Category.get_city(data);
            yield put(city_act({ ...city, city_loading: false }));
        }
    } catch (e) {
        console.log(e);
    }
}
// get_city
function* master_saga(data) {
    try {
        yield put(get_master_loading());
        const masters = yield Category.get_masters(data.payload);
        // if (masters.status === 200 || masters.status === 201) {
        yield put(get_master({ ...masters, ...data.payload.params, page: parseInt(data.payload.params.page) + 1 }));
        // }
    } catch (e) {
        console.log(e);
    }
}
function* selected_master_saga(data) {
    try {
        yield put(selected_master_loading());
        const masters = yield Category.get_selected_masters(data.payload);
        yield put(selected_master({ ...masters, ...data.payload, page: parseInt(data.payload.page) + 1 }));
    } catch (e) {
        console.log(e);
    }
}
function* master_data_saga(data) {
    try {
        const master = yield Category.get_master_data(data.payload);
        const service = yield Category.get_master_data_service(data.payload);
        // if (master.status < 400) {
        yield put(master_data_act({ ...master, master_services: service.results }));
        // document.cookie = `master=${data.payload.id}`;
        // }

    } catch (e) {
        console.log(e);
    }
}
function* get_service_by_address(data) {
    try {
        yield put(service_loader());
        const service = yield Category.get_service_by_address(data.payload);
        // if (master.status < 400) {
        yield put(master_data_act({ master_services: service }));
        // document.cookie = `master=${data.payload.id}`;
        // }

    } catch (e) {
        console.log(e);
    }
}
function* add_favorite_masters_saga(data) {
    try {
        const master = yield Category.add_favorite_masters(data.payload);
        yield put(add_favorite_masters(data.payload.master));
    } catch (e) {
        console.log(e);
    }
}
function* remove_favorite_masters_saga(data) {
    try {
        const master = yield Category.remove_favorite_masters(data.payload);
        yield put(add_favorite_masters(data.payload.master));

    } catch (e) {
        console.log(e);
    }
}
function* post_files_saga(data) {
    try {
        const files = yield Category.post_files(data.payload);
        yield put(post_files_act(files));
    } catch (e) {
        console.log(e);
    }
}
function* review_saga(data) {
    try {
        yield put(review_list_loading());
        const review = yield Category.review(data.payload);
        yield put(review_act({ ...review, page: data.payload.page ? (data.payload.page + 1) : 1 }));
    } catch (e) {
        console.log(e);
    }
}
function* master_filters_saga(data) {
    const { cb, on_map, ...rest } = data.payload;
    try {
        const masters_list = yield Category.masterFilters(rest);
        yield put(master_filter_act({ ...masters_list, params: rest }));
        if (!masters_list && !on_map) {
            error_notify();
        } else if (masters_list && masters_list.length === 0 && cb) {
            yield put(cb());
        }
    } catch (e) {
        console.log(e);
    }
}
function* available_days_saga(data) {
    try {
        // yield put(master_schedule_loading());
        const available_days_array = yield Category.availableDays(data.payload);
        // if (available_days.status >= 400) {
        //     error_notify("Something went wrong");
        //     yield put(available_days({
        //         calendar_value:
        //             calendar, ...rest
        //     }));
        // } else {
        yield put(available_days({
            available_days: available_days_array,
        }));
        // }
    } catch (e) {
        console.log(e);
    }
}
function* master_schedule_saga(data) {
    const { translate, ...rest } = data.payload;
    const value_list = rest?.day?.split("-");
    const calendar = {
        year: +value_list[0],
        month: +value_list[1],
        day: +value_list[2],
    };
    try {
        yield put(master_schedule_loading());
        const schedule = yield Category.masterSchedule(rest);
        if (schedule.status >= 400) {
            error_notify(schedule.data ? translate[schedule.data.message] : "");
            yield put(master_schedule({
                calendar_value:
                    calendar, ...rest
            }));
        } else {
            yield put(master_schedule({
                schedule: schedule.data,
                date: rest.date,
                calendar_value:
                    calendar, ...rest
            }));
        }
    } catch (e) {
        console.log(e);
    }
}
// SEND_ORDER_CODE_SAGA
function* send_new_order_code(data) {
    const { cb, phone_number } = data.payload;
    try {
        const code = yield Category.sendNewOrderCode(phone_number);
        if (code.message === 'OK') {
            cb();
        } else {
            error_notify(code.message);
        }
        // yield put(create_order(schedule));

    } catch (e) {
        console.log(e);
    }
}
function* get_closest_cities(data) {
    try {
        const cities = yield Category.get_closest_cities(data.payload);
            yield put(set_closest_cities(cities));

    } catch (e) {
        console.log(e);
    }
}
function* get_closest_masters_posts(data) {
    const {cb, ...rest}=data.payload;
    try {
        const cities = yield Category.masters_posts(rest);
            yield put(set_closest_masters_posts(cities));
            yield cb();

    } catch (e) {
        console.log(e);
    }
}
function* create_order_saga(data) {
    const { cb, openTab, translate, ...rest } = data.payload;
    try {
        yield put(create_order_loading());
        const schedule = rest.first_name ? yield Category.clientCreateOrder(rest) : yield Category.createOrder(rest);
        yield put(create_order(schedule));
        if (schedule.status < 400 || schedule.message) {
            yield put(delete_master_schedule());
            yield cb();

        } else if (schedule.iframe_url) {
            openTab(schedule.iframe_url);
        }
        else {
            error_notify(schedule.data ? i18n.t(schedule.data.message) : "");
        }
        yield put(create_order_loading());
    } catch (e) {
        console.log(e);
    }
}
export function* citySaga() {
    yield takeEvery(Service.GET_CITY_SAGA, get_city_saga);
}
export function* serviceSaga() {
    yield takeEvery(Service.GET_SERVICE_SAGA, service_saga);
}
export function* sendNewOrderCodeSaga() {
    yield takeEvery(Master.SEND_ORDER_CODE_SAGA, send_new_order_code);
}
export function* masterSaga() {
    // yield takeEvery(Service.GET_MASTER_SAGA, master_saga);
}
export function* selectedMasterSaga() {
    yield debounce(1000, Service.GET_SELECTED_MASTER_SAGA, selected_master_saga);
}
export function* masterDataSaga() {
    yield takeEvery(Master.GET_MASTER_DATA_SAGA, master_data_saga);
}
export function* postFilesSaga() {
    yield takeEvery(Master.POST_FILES_SAGA, post_files_saga);
}
export function* reviewSaga() {
    yield takeEvery(Master.REVIEW_SAGA, review_saga);
}
export function* masterFiltersSaga() {
    yield takeEvery(Master.MASTER_FILTER_SAGA, master_filters_saga);
}
export function* masterScheduleSaga() {
    yield takeEvery(Master.GET_MASTER_SCHEDULE_SAGA, master_schedule_saga);
}
export function* availableSaga() {
    yield takeEvery(Master.AVAILABLE_DAYS_SAGA, available_days_saga);
}
export function* createOrderSaga() {
    yield takeEvery(Master.CREATE_ORDER_SAGA, create_order_saga);
}
export function* addFavoriteMastersSaga() {
    yield takeEvery(Service.ADD_FAVORITE_MASTERS_SAGA, add_favorite_masters_saga);
}
export function* removeFavoriteMastersSaga() {
    yield takeEvery(Service.REMOVE_FAVORITE_MASTERS_SAGA, remove_favorite_masters_saga);
}
export function* getServiceByAddress() {
    yield takeEvery(Service.GET_SERVICES_BY_ADDRESS, get_service_by_address);
}
export function* getClosestCities() {
    yield takeEvery(Master.GET_CLOSEST_CITIES, get_closest_cities);
}
export function* getClosestMastersPosts() {
    yield takeEvery(Master.GET_CLOSEST_MASTERS_POSTS, get_closest_masters_posts);
}
